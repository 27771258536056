<template>
  <div class="sf-upload" :key="Math.random()">
    <el-upload
      class="avatar-uploader"
      ref="uploadFile"
      action="#"
      :multiple="multiple"
      :name="name"
      :showFileList="showFileList"
      :drag="drag"
      :accept="accept"
      :on-preview="onPreview"
      :on-remove="onRemove"
      :on-success="onUploadSuccess"
      :on-error="onUploadError"
      :on-progress="onFileProgress"
      :on-change="onFilesChange"
      :before-upload="onBeforeUpload"
      :before-remove="beforeRemove"
      :list-type="listType"
      :auto-upload="autoUpload"
      :disabled="isDisabled"
      :limit="limit"
      :on-exceed="onExceed"
      :http-request="uploadRequest"
    >
      <div v-loading="loading && listType === 'text'">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <div class="avatar-uploader-content" v-else>
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <p class="avatar-uploader-text">上传照片</p>
        </div>
      </div>
      <div class="el-img-mask" :class="{'mask-hover': !aDisabled }" v-if="imageUrl" @click="handleRemove">
        <span class="el-upload-delete">
          <i class="el-icon-delete"></i>
        </span>
      </div>

      <div v-if="listType === 'picture-card'" slot="file" slot-scope="{ file }">
        <el-progress
          v-show="file.status !== 'success'"
          type="circle"
          :percentage="file.percentage"
        ></el-progress>
        <img
          class="el-upload-list__item-thumbnail"
          :src="getFileFullUrl(isImagePath ? file.relativePath : file.url)"
          alt=""
        />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="showDownloadBtn"
            class="el-upload-list__item-download"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download"></i>
          </span>
          <span
            v-if="!aDisabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <template v-else-if="listType === 'text'">
        <a class="el-upload-list__item-name">
          <i class="el-icon-document">{{ file.originalName }}</i>
        </a>
        <label class="el-upload-list__item-status-label">
          <i class="el-icon-upload-success el-icon-circle-check"></i>
        </label>
        <i
          v-if="showDownloadBtn"
          class="el-icon-download"
          @click="handleDownload(file)"
        ></i>
        <i
          class="el-icon-close"
          @click="handleRemove(file)"
        ></i>
      </template>
      <el-button
        v-if="!autoUpload && showSubmitBtn"
        type="primary"
        @click.stop="
          () => {
            $refs.uploadFile.submit()
          }
        "
      >
        开始上传
      </el-button>
      <slot name="clear"></slot>
      <div slot="tip" class="el-upload__tip">
        <p>图片尺寸800px*800px以上，大小10MB以内，格式png/jpg/jpeg，最多可上传1张</p>
        <p>注意:如果图片像素太小，招商人员将无法对您的资质正确评估，因此请务必按照图片要求上传。</p>
      </div>
    </el-upload>
  </div>
</template>

<script>
let prevOverflow = null
import { uploadFiles, getUploadFile } from '@/api'

export default {
  name: 'SfUpload',
  props: {
    src: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    showDownloadBtn: {
      type: Boolean,
      default: true
    },
    showSubmitBtn: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: false
    },
    aDisabled: {
      type: Boolean,
      default: false
    },
    onPreview: Function,
    onRemove: Function,
    onSuccess: Function,
    onError: Function,
    onProgress: Function,
    onChange: Function,
    beforeUpload: Function,
    beforeRemove: Function,
    // text/picture/picture-card
    listType: {
      type: String,
      default: 'text'
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    limit: Number,
    onExceed: Function,
    isImagePath: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      accept: '.jpg,.jpeg,.png',
      name: 'files',
      fileList: [],
      imageUrl: '',
      loading: false,
      showViewer: false,
      imageIndex: 0,
      isDisabled: false,
      uploadValue: this.value,
      axiosDownloadFileSuf: ['jpeg', 'jpg', 'gif', 'png', 'txt', 'pdf']
    }
  },
  computed: {
    fileIdList: {
      get() {
        return this.fileList.map(item => item.id)
      },
      set() {
        // this.$emit('input', val)
      }
    },
    imageFileList() {
      if (this.listType === 'picture-card') {
        return this.fileList.map(item => this.getFileFullUrl(item.relativePath))
      }
      return []
    }
  },
  created () {
    this.imageUrl = this.src
    this.isDisabled = !!this.src || this.value.length !== 0
  },
  methods: {
    /**
     * 清空已上传的文件列表（该方法不支持在 before-upload 中调用）
     */
    clearFiles() {
      this.$refs.uploadFile.clearFiles()
    },

    /**
     * 取消上传请求
     * @param file
     */
    abort(file) {
      this.$refs.uploadFile.abort(file)
    },

    /**
     * 手动上传文件列表
     */
    submit() {
      this.$refs.uploadFile.submit()
    },
    /**
     * 文件上传请求
     * @param fileObj
     */
    uploadRequest(fileObj) {
      const param = {
        file: fileObj.file,
        fileType: 4
      }
      return uploadFiles(param, e => {
        if (e.total > 0) {
          e.percent = (e.loaded / e.total) * 100
        }
        this.onFileProgress(e, fileObj.file)
      })
    },
    // 设置加载loading
    onBeforeUpload(file) {
      this.loading = true
      let flag = true
      let suffix = file.name.slice(file.name.indexOf('.') + 1).toLowerCase()
      if (['jpg', 'png', 'jpeg'].indexOf(suffix) === -1) {
        this.loading = false
        this.$message.warning(`文件格式错误`)
        return false
      }
      if (file.size / 1024 / 1024 > 10) {
        this.loading = false
        this.$message.warning(`文件不可超过10M`)
        return false
      }
      if (this.beforeUpload) {
        flag = this.beforeUpload(file)
        if (!flag) {
          this.loading = false
        }
      }
      return flag
    },
    // 文件变更
    onFilesChange(data) {
      if (this.onChange && data.status === 'ready') {
        this.onChange(data)
      }
    },
    onFileProgress(ev, rawFile) {
      const file = this.$refs.uploadFile.getFile(rawFile)
      file.status = 'uploading'
      file.percentage = ev.percent || 0
      this.onProgress && this.onProgress(ev, file)
    },
    handlePictureCardPreview(file) {
      this.imageIndex = this.fileList.indexOf(file)
      // prevent body scroll
      prevOverflow = document.body.style.overflow
      document.body.style.overflow = 'hidden'
      this.showViewer = true
    },
    handleDownload() {
      // d？ownload(this.getFileFullUrl(file.relativePath))
    },
    handleRemove(file) {
      if (this.aDisabled) return
      this.imageUrl = ''
      this.uploadValue = []
      setTimeout(() => {
        this.isDisabled = false
      }, 100)
      
      // this.fileList.splice(this.fileList.indexOf(file), 1)
      // this.fileIdList = this.fileList.map(item => item.id)
      this.onRemove && this.onRemove(file)
      this.$emit('onRemove')
    },
    closeViewer() {
      document.body.style.overflow = prevOverflow
      this.showViewer = false
    },
    onUploadSuccess(res, file) {
      res = res.data.data.res
      this.imageUrl = res.url
      // this.value = [res.url]

      this.uploadValue.push(res.id)
      this.isDisabled = true
      this.loading = false
      this.$message.success('上传成功')
      // 文件列表中添加文件
      this.fileList.push({
        ...res
      })
      // 调用回调s
      setTimeout(() => {
        this.$emit('onSuccess')
      }, 200)
      
      this.onSuccess && this.onSuccess(res, file)

      // this.fileIdList = this.fileList.map(item => item && item.id)
      
    },
    onUploadError(res, file) {
      this.loading = false
      this.onError && this.onError(res, file)
    },
    getFileFullUrl(url) {
      let fullUrl = ''
      if (url) {
        fullUrl = /(http|https):\/\/([\w.]+\/?)\S*/.test(url)
          ? url
          : process.env.VUE_APP_BASE_FILE_PATH + url
      }
      return fullUrl
    },
    /**
     * 获取头像预览地址
     * @param url
     * @returns {string}
     */
    getAvatarPreviewUrl(url) {
      let fullUrl = ''
      if (url) {
        fullUrl = /(http|https):\/\/([\w.]+\/?)\S*/.test(url)
          ? url
          : process.env.VUE_APP_BASE_AVATAR_PREVIEW + url
      }
      return fullUrl
    }
  },
  watch: {
    value: {
      handler(val) {
        this.uploadValue = val
        if (val && val.length) {
          // 初次加载获取文件列表
          let requests = val.map(id => id && getUploadFile(id))
          Promise.all(requests).then(result => {
            if (!result) return
            this.fileList = result.map(res => {
              return res.data.res
            })
          })
        }
      },
      deep: true,
      immediate: true
    },
    src (n) {
      this.imageUrl = n
      this.isDisabled = n !== ''
    },
    uploadValue (n) {
      this.$emit('input', n)
    },
    aDisabled (n) {
      this.isDisabled = n
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload {
  text-align: left;
}
::v-deep .el-upload-list__item {
  transition: none;
}
::v-deep .el-upload-list--picture-card.is-disabled + .el-upload {
  display: none;
}
::v-deep .el-upload--text {
  width: 94px;
  height: 94px;
  position: relative;

  .el-img-mask {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 94px;
    height: 94px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;

    &.mask-hover:hover {
      opacity: 1;
    }

    .el-upload-delete {
      color: #fff;
      font-size: 20px;
    }

  }
}
.el-upload-list--text {
  .el-upload-list__item {
    .el-icon-close,
    .el-icon-download {
      // display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
    .el-icon-download {
      right: 25px;
    }

    &:hover {
      .el-icon-close,
      .el-icon-download {
        display: inline-block;
      }

      .el-progress__text {
        display: none;
      }
    }
  }
}

.avatar-uploader {
  cursor: pointer;
  position: relative;
  overflow: hidden;

  p {
    height: 20px;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }
  .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-content {
    border: 1px dashed #d9d9d9;
    background-color: #f7f8fc;
    border-radius: 6px;
    width: 96px;
    height: 96px;

    .avatar-uploader-icon {
      font-size: 32px;
      color: #8c939d;
      width: 94px;
      height: 64px;
      line-height: 64px;
      text-align: center;
    }

    .avatar-uploader-text {
      color: #8c939d;
      text-align: center;
      width: 94px;
      height: 30px;
      margin: 0;
      padding: 0;
    }
  }

  .avatar {
    width: 94px;
    height: 94px;
    display: block;
  }
}
</style>
