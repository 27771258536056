<template>
  <div class="wrap">
    <div class="content">
      <h2 style="font-size: 20px;">品牌信息及意向信息</h2>

      <el-card class="nav">
        <!-- 步骤条 -->
        <Steps :active-index="3" :is-show="merId !== ''"></Steps>
      </el-card>
      <el-card class="merchant_card">
        <!-- 商户信息 -->
        <el-form
          ref="intentionForm"
          :labelWidth="labelWidth1"
          :model="intentionForm"
          :rules="brandRules"
        >
          <p class="title">意向信息</p>
          <el-divider class="divider"></el-divider>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="意向城市:" prop="cooperateCity">
                <el-input v-model.trim="intentionForm.cooperateCity"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="入驻模式:" prop="entryType">
                <el-radio-group v-model="intentionForm.entryType">
                  <el-radio
                    v-for="item in entryType1"
                    :key="item.dictValue"
                    :label="item.dictValue"
                    >{{ item.dictLabel }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-tabs 
          class="tabs"
          :class="{'hide_close': intentionForm.entryBrandCaUpdateVos.length <= 1}"
          v-model="currentTab"
          type="card"
          editable 
          @edit="handleTabsEdit">
          <el-tab-pane
            v-for="(item, index) in intentionForm.entryBrandCaUpdateVos"
            :key="item.name"
            :label="item.title"
            :name="item.name">
            <el-form
              size="small"
              label-position="right"
              :rules="brandRules"
              :ref="'brandForm' + index"
              :model="intentionForm.entryBrandCaUpdateVos[index]"
              :labelWidth="labelWidth">
              
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="品牌名称:" prop="zhName">
                    <span>{{ intentionForm.entryBrandCaUpdateVos[index].zhName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-button size="small" type="primary" @click="selectBrand">选择品牌</el-button>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="品牌级别:" prop="popLevel">
                    <span v-if="intentionForm.entryBrandCaUpdateVos[index].popLevel !== '' && item.popLevel == 0">国际品牌</span>
                    <span v-if="intentionForm.entryBrandCaUpdateVos[index].popLevel == 1">国内连锁品牌</span>
                    <span v-if="intentionForm.entryBrandCaUpdateVos[index].popLevel == 2">国内区域品牌</span>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="品牌LOGO:">
                    <el-image
                      v-if="intentionForm.entryBrandCaUpdateVos[index].relativeURL"
                      style="width: 100px; height: 100px"
                      :src="intentionForm.entryBrandCaUpdateVos[index].relativeURL"
                      fit="scale-down"
                    >
                    </el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="品牌业态:" prop="caId">
                    <el-cascader
                      v-model="intentionForm.entryBrandCaUpdateVos[index].caId"
                      :options="treeCats"
                      :props="props"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="商标证:" prop="trademarkPath">
                    <Upload
                      v-model="intentionForm.entryBrandCaUpdateVos[index].trademarkPath"
                      :on-success="handleTrademarkPath"
                      :on-remove="handleRemoveTradePath"
                      :src="intentionForm.entryBrandCaUpdateVos[index].trademarkUrl"
                    ></Upload>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="商品源原产地:" prop="goodOrigin">
                    <el-radio-group v-model="intentionForm.entryBrandCaUpdateVos[index].goodOrigin">
                      <el-radio
                        v-for="ite in goodOriginList"
                        :key="ite.dictValue"
                        :label="ite.dictValue"
                        >{{ ite.dictLabel }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="品牌方是否为个人:" prop="brandIsPersonal">
                    <el-radio-group v-model="intentionForm.entryBrandCaUpdateVos[index].brandIsPersonal">
                      <el-radio
                        v-for="ite in yesOrNo"
                        :key="ite.dictValue"
                        :label="ite.dictValue"
                        >{{ ite.dictLabel }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item
                    label="品牌授权方是否是境内公司:"
                    prop="brandAuthIsDomestic"
                  >
                    <el-radio-group v-model="intentionForm.entryBrandCaUpdateVos[index].brandAuthIsDomestic">
                      <el-radio
                        v-for="ite in yesOrNo"
                        :key="ite.dictValue"
                        :label="ite.dictValue"
                        >{{ ite.dictLabel }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col>
                  <p class="title">经营资质</p>
                  <el-divider class="divider"></el-divider>
                  <div style="width: 95%;margin: 20px auto;">
                    <el-divider content-position="left">招商说明和要求：</el-divider>
                    <el-table
                      border
                      stripe
                      :header-cell-style="{backgroundColor: 'rgb(243, 243, 243)', color: 'rgb(102, 102, 102)'}"
                      :data="applyForTableData"
                      style="width: 100%">
                      <el-table-column prop="status" label="经营资质列表">
                      </el-table-column>
                      <el-table-column prop="desc" label="备注">
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
                <el-col>
                  <el-form-item
                    label="经营资质上传:"
                    prop="trademarkAuthQualificationsURL"
                  >
                    <el-upload
                      :key="intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsURL.length"
                      ref="formUpload"
                      :action="uploadUrl"
                      list-type="picture-card"
                      accept="accept"
                      :limit="imgLimit"
                      v-model="intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsURL"
                      :file-list="getList(intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsURL)"
                      :multiple="isMultiple"
                      :on-success="handleAvatarSuccess"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :on-exceed="handleExceed"
                      :beforeUpload="file => onBeforeUploadBrand(file, 10, intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsURL)"
                    >
                      <i class="el-icon-plus"></i>
                      <div slot="tip" class="el-upload__tip">
                        <p style="line-height: 20px;">图片尺寸800px*800px以上，大小10MB以内，格式png/jpg/jpeg，最多可上传1张</p>
                        <p style="line-height: 20px;">注意:如果图片像素太小，招商人员将无法对您的资质正确评估，因此请务必按照图片要求上传。</p>
                      </div>
                    </el-upload>
                    
                    <el-dialog :visible.sync="dialogVisible1">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <div class="btn-group">
        <el-button class="btn" size="small" @click="goBack">上一步</el-button>
        <el-button class="btn" size="small" @click="handleSave">临时保存</el-button>
        <el-button class="btn" size="small" type="primary" @click="submit">下一步</el-button>
      </div>
      <div class="findBrand">
        <el-dialog title="选择品牌" :visible.sync="brandDialog" width="60%">
          <!-- :before-close="handleClose" -->
          <el-form size="mini" :inline="true" :model="queryForm">
            <el-form-item label="品牌名称" label-width="80px">
              <el-input v-model.trim="queryForm.zhName"></el-input>
            </el-form-item>
            <el-form-item label="品牌级别" label-width="80px">
              <el-select v-model="queryForm.popLevel" placeholder="请选择">
                <el-option
                  v-for="item in brandLevel"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="findBrand">查询</el-button>
              <el-button @click="resetList">重置</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="brandTable" stripe border style="width: 100%">
            <el-table-column
              label="序号"
              type="index"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="品牌名称"
              prop="zhName"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="品牌级别"
              prop="popLevel"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.popLevel == 0">国际品牌</span>
                <span v-if="scope.row.popLevel == 1">国内连锁品牌</span>
                <span v-if="scope.row.popLevel == 2">国内区域品牌</span>
              </template>
            </el-table-column>
            <el-table-column label="品牌LOGO" width="220" align="center">
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.imgUrl"
                  style="width: 50px; height: 50px"
                  :src="scope.row.imgUrl"
                  fit="scale-down"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
                <el-link
                  :underline="false"
                  type="primary"
                  @click="selectAddBrand(scope.row)">
                  选择
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50]"
            :page-size="pageInfo.pageSize"
            :current-page="pageInfo.pagenum"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
          >
          </el-pagination>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="addBrand">添加新品牌</el-button>
          </span>
        </el-dialog>
      </div>

      <el-dialog title="添加品牌" :visible.sync="addBrandDialog" width="50%">
        <el-form
          size="small"
          :model="addBrandForm"
          :rules="brandRules"
          ref="addBrandForm">
          <el-form-item label-width="80px" label="品牌名称" prop="zhName">
            <el-input v-model.trim="addBrandForm.zhName"></el-input>
          </el-form-item>
          <el-form-item label-width="80px" label="品牌级别" prop="popLevel">
            <el-select v-model="addBrandForm.popLevel" placeholder="请选择">
              <el-option
                v-for="item in brandLevel"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌LOGO">
            <Upload v-model="logoList"></Upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addBrandDialog = false">取 消</el-button>
          <el-button type="primary" @click="addSelect">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getCaByLevel,
  getBrandLists,
  findList,
  getUploadFile,
  addBrand,
  getBrandImageId
} from "@/api/index.js";
import { makeElementTree } from "@/utils/util";
import { urlValidate } from '@/utils/validate'
import { mapGetters } from "vuex";
import Upload from "@/components/Upload";
import Steps from "@/components/Steps";

export default {
  components: {
    Upload,
    Steps,
  },
  // props: [],
  data() {
    return {
      merId: '',
      dialogImageUrl: "",
      dialogVisible1: false,
      actionUrl: process.env.VUE_APP_BASE_URL,
      productImgs: [],
      idArr: [],
      urlArr: [],
      isMultiple: true,
      imgLimit: 5,
      // 表单宽度
      labelWidth: "240px",
      labelWidth1: "240px",
      // 意向城市
      intentionForm: {
        cooperateCity: "",
        entryType: "",
        entryBrandCaUpdateVos: [
          {
            title: "品牌1",
            name: "0",
            zhName: "",
            popLevel: "",
            relativeURL: "",
            // 品牌方id
            brandId: "",
            caId: "",
            // 商标id
            trademarkPath: [],
            // 商标网络地址
            trademarkUrl: null,
            goodOrigin: "",
            brandIsPersonal: "",
            brandAuthIsDomestic: "",
            // 经营资质id
            trademarkAuthQualificationsList: [],
            // 经营资质 网络地址
            trademarkAuthQualificationsURL: [],
            trademarkAuthQualifications: []
          },
        ]
      },
      tableData: [],
      // 查看图片  图片预览
      dialogVisible: false, // 是否预览
      // 选项卡 v-model
      currentTab: "0",
      tabIndex: 0,
      // 选择品牌弹框
      brandDialog: false,
      // 查询表单
      queryForm: {
        zhName: "",
        popLevel: "",
      },
      // 添加品牌弹框
      addBrandDialog: false,
      // 选择品牌表格数据
      brandTable: [],
      // 添加品牌from表单
      addBrandForm: {
        zhName: "",
        popLevel: "",
      },
      // 添加品牌 id
      logoList: [],

      // 品牌业态数据
      treeCats: [],
      // 分页数据
      pageInfo: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      // 品牌img
      brandImg: "",
      // 添加品牌img URL
      addBrandImhUrl: "",

      brandRules: {
        cooperateCity: [
          { required: true, message: "请输入意向", trigger: "blur" },
          { max: 50, message: "最多输入50个字符", trigger: "change" },
        ],
        entryType: [
          // { required: true, message: "请选择入驻模式", trigger: "change" },
        ],
        zhName: [
          { required: true, message: "请输入品牌名称", trigger: "change" },
        ],
        popLevel: [
          { required: true, message: "请输入品牌级别", trigger: "change" },
        ],
        caId: [
          { required: true, message: "请选择品牌业态", trigger: "change" },
        ],
        goodOrigin: [
          { required: true, message: "请选择商品源原产地", trigger: "change" },
        ],
        brandIsPersonal: [
          {
            required: true,
            message: "请选择品牌方是否为个人",
            trigger: "change",
          },
        ],
        brandAuthIsDomestic: [
          {
            required: true,
            message: "请选择品牌授权方是否是境内公司",
            trigger: "change",
          },
        ],
        trademarkAuthQualificationsURL: [
          {
            type: "array",
            validator: urlValidate,
            required: true,
            message: "请上传资质图片",
            trigger: "change",
          }
        ],
      },
      //
      props: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
      // 标签页
      accept: '.jpg,.jpeg,.png',
      applyForTableData: [
        {
          status: "商标注册证/商品注册申请受理通知书",
          desc:
            "由国家商标总局颁发的商标注册证（R标）或商标注册申请受理通知书（TM标），可接受商标局查询截图，需加盖公司红章。若商标发生过变更，需上传变更证明。扫描原件或复印件加盖入驻公司红章。",
        },
        {
          status: "其他资质",
          desc:
            "需提交特殊产品资质的类目请将资质上传在此处，包括全国工业生产许可证、3C、医疗器械生产许可证、化妆品生产企业卫生许可证等，根据具体类目资质要求提交",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["entryType", "goodOriginList", "yesOrNo", "brandLevel"]),
    entryType1 () {
      return this.entryType ? this.entryType.slice(0, 1) : []
    },
    uploadUrl () {
      return `/${process.env.VUE_APP_MODE === 'production' ? 'api-match' : 'api'}/gm-nxcloud-resource/api/nxcloud/res/upload`
    }
  },
  watch: {
		// 入驻模式默认选中第一个线上门店
		entryType1(newVal){
			this.intentionForm.entryType = newVal[0].dictValue;
      // 入驻模式校验不生效？？？不知道为什么（先注释校验）
			this.$nextTick(() =>{
        this.$refs.intentionForm.validateField("entryType");
			})
		}
	},
  async created() {
    // 获取业态数据
    this.tabIndex = 0;
    const data = JSON.parse(localStorage.getItem("merchant_info")) || {};
    this.merId = data.merId

    let res = await getCaByLevel();
    const treeCats = makeElementTree({
      pid: null, // 顶级分类的 pid 为 null
      list: res.data.data, // 将原始数组参数传进去
      pidFiled: "parentId", // 在pid 字段名为 parentId
      labelFiled: "name", //  label 字段名为 name
      valueFiled: "caId", // value 字段名为 caId
    });
    this.treeCats = treeCats
    this.$store.dispatch("getAllDictionaryList")

    if (Object.keys(data).length !== 0) {
      this.intentionForm = data;

      if (!this.intentionForm.entryBrandCaUpdateVos || !this.intentionForm.entryBrandCaUpdateVos.length) {
        this.$set(this.intentionForm, 'entryBrandCaUpdateVos', [])
        this.$set(this.intentionForm.entryBrandCaUpdateVos, 0, 
        {
            title: "品牌1",
            name: "0",
            zhName: "",
            popLevel: "",
            relativeURL: "",
            // 品牌方id
            brandId: "",
            caId: "",
            // 商标id
            trademarkPath: [],
            // 商标网络地址
            trademarkUrl: null,
            goodOrigin: "",
            brandIsPersonal: "",
            brandAuthIsDomestic: "",
            // 经营资质id
            trademarkAuthQualificationsList: [],
            // 经营资质 网络地址
            trademarkAuthQualificationsURL: [],
            trademarkAuthQualifications: []
          })
      } else {
        for (var i = 0; i < this.intentionForm.entryBrandCaUpdateVos.length; i++) {
          // 品牌信息
          this.intentionForm.entryBrandCaUpdateVos[i].title = '品牌'
          this.intentionForm.entryBrandCaUpdateVos[i].name = '' + i
          this.intentionForm.entryBrandCaUpdateVos[i].closable = true
          if (!this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsList) {
            this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsList = []
            this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsURL = []
          }
          const brandId = this.intentionForm.entryBrandCaUpdateVos[i].brandId
          if (brandId) {
            let res = await getBrandImageId(brandId)
            const logoResId = res.data.data.logoResId
            if (logoResId) {
              res = await getUploadFile(logoResId)
              // this.intentionForm.entryBrandCaUpdateVos[i].relativeURL = res.data.data.res.url
              this.$set(this.intentionForm.entryBrandCaUpdateVos[i], 'relativeURL', res.data.data.res.url)
            }
          }
          
          // 获取资质图片
          const list = this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualifications
          if (list && list.length > 0) {
            this.$set(this.intentionForm.entryBrandCaUpdateVos[i], 'trademarkAuthQualificationsURL', [])
            this.$set(this.intentionForm.entryBrandCaUpdateVos[i], 'trademarkAuthQualificationsList', [])
            for (var x = 0; x < list.length; x++) {
              let res = await getUploadFile(list[x].taqImagePath)
              this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsURL.splice(x, 1, res.data.data.res.url)
              this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsList.splice(x, 1, res.data.data.res.id)
              // this.$set(this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsURL, x, res.data.data.res.url)
              // this.$set(this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsList, x, res.data.data.res.id)
              this.$forceUpdate()
            }
          }

          
          // 获取商标证图片地址
          const path = this.intentionForm.entryBrandCaUpdateVos[i].trademarkPath
          if (path && path.length > 0) {
            let res = await getUploadFile(path[0])
            this.$set(this.intentionForm.entryBrandCaUpdateVos[i], 'trademarkUrl', res.data.data.res.url)
          }
        }
      }
    }
  },
  methods: {
    validate(fn) {
      this.$refs.intentionForm.validate().then(valid => {
        if (valid) {
          let flag = true
          for (var i = 0; i < this.intentionForm.entryBrandCaUpdateVos.length; i++) {
            
            this.$refs[`brandForm${i}`][0].validate(vali => {
              
              if (!vali) {
                flag = false
                const selfValid = this.selfValidate(this.intentionForm.entryBrandCaUpdateVos[i])
                if (!selfValid) {
                  flag = false
                } else {
                  if (this.intentionForm.entryBrandCaUpdateVos[i].trademarkAuthQualificationsURL.length > 0) {
                  this.$refs[`brandForm${i}`][0].clearValidate('trademarkAuthQualificationsURL')
                  flag = true
                }
                }
                
                this.currentTab = '' + this.intentionForm.entryBrandCaUpdateVos[i].name
              }
            })
            if (!flag) {
              break
            }
          }
          fn(flag)
        }
      }).catch(() => {
        fn(false)
      })
    },
    selfValidate (obj) {
      return obj.cooperateCity !== ''
      && obj.entryType !== ''
      && obj.zhName !== ''
      && obj.popLevel !== ''
      && obj.caId !== ''
      && obj.goodOrigin !== ''
      && obj.brandIsPersonal !== ''
      && obj.brandAuthIsDomestic !== ''
    },
    // 商户基本信息提交
    submit() {
      this.validate(valid => {
        if (!valid) return;
        const merchantInfo = JSON.parse(localStorage.getItem('merchant_info')) || {}
        this.intentionForm = {
          ...merchantInfo,
          ...this.intentionForm
        }
        this.intentionForm.entryBrandCaUpdateVos.forEach(item => {
          item.caId = (typeof item.caId === 'string' ? item.caId : item.caId[item.caId.length - 1])
        })
        localStorage.setItem("merchant_info", JSON.stringify(this.intentionForm));
        this.$store.commit('SET_UPDATE_STATUS', 1)
        this.$router.push("submitInfo");
        
      })
    },
    // 临时保存
    handleSave() {
      const merchantInfo = JSON.parse(localStorage.getItem('merchant_info')) || {}
      this.intentionForm = {
        ...merchantInfo,
        ...this.intentionForm
      }
      localStorage.setItem("merchant_info", JSON.stringify(this.intentionForm));
      this.$message({
        showClose: true,
        message: '保存成功!',
        type: 'success'
      });
    },
    // 返回键
    goBack() {
      this.$router.push("companyInfo");
    },
    // 选择品牌
    selectBrand() {
      this.brandDialog = true;
      this.getBrandLists();
    },
    // 添加品牌
    addSelect() {
      this.$refs["addBrandForm"].validate(async (valid) => {
        if (!valid) return;
        let params = {
          ...this.addBrandForm,
          logo: this.logoList[0],
        };
        let res = await addBrand(params);
        if (res.data.code != 0) this.$message(res.data.message);

        this.addBrandDialog = false
        this.getBrandLists()
      });
    },
    // 选择添加品牌
    async selectAddBrand(val) {
      let index = this.currentTab;
      this.$set(
        this.intentionForm.entryBrandCaUpdateVos,
        index,
        Object.assign(this.intentionForm.entryBrandCaUpdateVos[index], {
          zhName: val.zhName,
          popLevel: val.popLevel,
          relativeURL: val.imgUrl,
          brandId: val.id,
          logoResId: val.logoResId
        })
      );
      if (val.logoResId) {
        const res = await getUploadFile(val.logoResId)
        this.intentionForm.entryBrandCaUpdateVos[index].relectiveURL = res.data.data.res.url
      }
      this.$refs[`brandForm${this.currentTab}`][0].validateField('zhName')
      this.$refs[`brandForm${this.currentTab}`][0].validateField('popLevel')
      this.brandDialog = false;
    },
    // 添加标签页
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        if (this.intentionForm.entryBrandCaUpdateVos.length >= 3) {
          return this.$message.warning("最多添加3个品牌！");
        }
        let newTabName = this.intentionForm.entryBrandCaUpdateVos.length + "";
        this.intentionForm.entryBrandCaUpdateVos.push({
          title: "品牌",
          name: newTabName,
          zhName: "",
          popLevel: "",
          relativeURL: "",
          // 品牌方id
          brandId: "",
          caId: "",
          // 商标id
          trademarkPath: [],
          // 商标网络地址
          trademarkUrl: null,
          goodOrigin: "",
          brandIsPersonal: "",
          brandAuthIsDomestic: "",
          // 经营资质id
          trademarkAuthQualificationsList: [],
          // 经营资质 网络地址
          trademarkAuthQualificationsURL: [],
          trademarkAuthQualifications: []
        });
        this.currentTab = newTabName;
      }
      if (action === "remove") {
        if (this.intentionForm.entryBrandCaUpdateVos.length <= 1) {
          return;
        }
        let tabs = this.intentionForm.entryBrandCaUpdateVos;
        this.intentionForm.entryBrandCaUpdateVos = tabs.filter(
          (tab) => tab.name !== targetName
        );
        if (this.currentTab == this.intentionForm.entryBrandCaUpdateVos.length) {
          this.currentTab = this.intentionForm.entryBrandCaUpdateVos.length - 1 + ''
        }
        this.intentionForm.entryBrandCaUpdateVos.map((item, index) => {
          item.name = '' + index
        })
      }
    },

    // 分页事件 条数
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getBrandLists();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageInfo.current = val;
      this.getBrandLists();
    },
    // 添加品牌
    addBrand() {
      this.addBrandDialog = true;
    },
    // 获取品牌列表
    async getBrandLists() {
      // 查询品牌信息
      let res = await getBrandLists(this.pageInfo);
      if (res.data.code != 0) this.$message(res.data.message);
      let list = res.data.data;
      this.brandTable = list.records;
      this.pageInfo.total = list.total;
      this.pageInfo.current = list.current;
      this.pageInfo.pageSize = list.size;
      this.getListImg(list.records).then((res) => {
        this.brandTable.forEach((item, index) => {
          res.forEach((ite) => {
            if (ite.data && ite.data.res.id == item.logoResId) {
              this.$set(this.brandTable, index, {
                ...item,
                imgUrl: ite.data.res.url,
              });
            }
          });
        });
      });
    },
    // 请求品牌图片
    async getListImg(list) {
      // 请求图片
      let res = list.map((item) => {
        return new Promise((resolve, reject) => {
          if (item.logoResId && item.logoResId != "") {
            getUploadFile(item.logoResId)
              .then((res) => {
                // console.log(res);
                resolve(res.data);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve({});
          }
        });
      });
      return await Promise.all(res);
    },
    // 查找品牌
    async findBrand() {
      
      let params = { ...this.pageInfo, ...this.queryForm };
      let res = await findList(params);
      if (res.data.code != 0) return this.message(res.data.message);
      this.brandTable = res.data.data.records;
      this.pageInfo.total = res.data.data.total;
      this.pageInfo.current = res.data.data.current;
      this.pageInfo.pageSize = res.data.data.size;
      this.getListImg(this.brandTable).then((res) => {
        this.brandTable.forEach((item, index) => {
          res.forEach((ite) => {
            if (ite.data && ite.data.res.id == item.logoResId) {
              this.$set(this.brandTable, index, {
                ...item,
                imgUrl: ite.data.res.url,
              });
            }
          });
        });
      });
    },
    // 重置
    async resetList() {
      this.queryForm = {
        zhName: "",
        popLevel: ""
      }
      this.getBrandLists()
    },
    // 商标上传成功  获取网络地址
    handleTrademarkPath(res) {
      // 1 保存地址，2 保存id
      let index = this.currentTab;
      this.intentionForm.entryBrandCaUpdateVos[index].trademarkUrl = res.url
      this.$set(this.intentionForm.entryBrandCaUpdateVos[index], 'trademarkPath', [res.id])
    },
    handleRemoveTradePath () {
      let index = this.currentTab;
      this.intentionForm.entryBrandCaUpdateVos[index].trademarkUrl = ''
      this.$set(this.intentionForm.entryBrandCaUpdateVos[index], 'trademarkPath', [])
    },
    handleRemove({ url }) {
      let index = this.currentTab;
      const urlIndex = this.intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsURL.indexOf(url)
      if (urlIndex !== -1) {
        this.intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsURL.splice(urlIndex, 1)
        this.intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualificationsList.splice(urlIndex, 1)
        this.intentionForm.entryBrandCaUpdateVos[index].trademarkAuthQualifications.splice(urlIndex, 1)
        this.$forceUpdate()
      }
    },
    handlePictureCardPreview(file) {
      //预览图片时调用
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(file, fileList) {
      //图片上传超过数量限制
      this.$message.error("上传图片不能超过6张!");
      console.log(file, fileList);
    },
    handleAvatarSuccess (res) {
      this.$nextTick(() => {
        //图片上传成功
        res = res.data.res;
        let len = this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualificationsURL.length
        // let idList = this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualificationsList
        // let urlList = this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualificationsURL
        // let arr = this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualifications
        // idList.push(res.id)
        // urlList.push(res.url)
        // arr.push({ taqImagePath: res.id })
        this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualificationsURL.splice(len, 1, res.url)
        this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualificationsList.splice(len, 1, res.id)
        this.intentionForm.entryBrandCaUpdateVos[this.currentTab].trademarkAuthQualifications.splice(len, 1, {taqImagePath: res.id })
        // this.$set(this.intentionForm.entryBrandCaUpdateVos[this.currentTab], 'trademarkAuthQualificationsList', idList)
        // this.$set(this.intentionForm.entryBrandCaUpdateVos[this.currentTab], 'trademarkAuthQualificationsURL', urlList)
        // this.$set(this.intentionForm.entryBrandCaUpdateVos[this.currentTab], 'trademarkAuthQualifications', arr)
        this.$refs['brandForm' + this.currentTab][0].validateField('trademarkAuthQualificationsURL')
        this.$forceUpdate()
      })
    },
    getList(list) {
      if (list && list.length > 0) {
        return list.map(item => {
          return { url: item }
        })
      }
      return []
    },
    onBeforeUploadBrand(file, size, limit) {
      if (limit && limit.length === 5) {
        this.$message.warning('最多上传5张图片！')
        return false
      }
      let suffix = file.name.slice(file.name.indexOf('.') + 1).toLowerCase()
      if (['jpg', 'png', 'jpeg'].indexOf(suffix) === -1) {
        this.$message.warning(`文件格式错误`)
        return false
      }
      if (file.size / 1024 / 1024 > size) {
        this.$message.warning(`文件不可超过${size}M`)
        return false
      }
      return true
    }
  },
};
</script>

<style scoped lang="scss">
/deep/ .hide_close .el-tabs__item.is-active.is-closable .el-icon-close {
  display: none;
}
.nav {
  width: 100%;
  margin: 20px auto;
}
p {
  margin: 0;
  padding: 0;
}
.title {
  font-size: 18px;
  margin: 0;
}
.divider {
  margin: 8px 0 24px 0;
}
.tabs {
  margin: 0 auto;
}
.wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #edf3fe;
  padding-top: 60px;
  // 步骤条区域
  .header {
    height: 164px;
    margin-bottom: 1.9%;
    margin-top: 20px;
    padding-top: 40px;
    box-sizing: border-box;
  }
  .content {
    width: 80%;
    min-height: 500px;
    margin: 40px auto;
  }
}
.merchant_card {
  width: 100%;
  margin-bottom: 44px;
}
/deep/.bottom_card .el-tabs__header {
  display: none;
}
// tabs选中高亮
/deep/ .is-active {
  background: #6d59e1;
  color: #ffffff;
  border-radius: 5px;
}
/deep/ .el-button--primary {
  background: #6d59e1;
}
/deep/ .findBrand .el-dialog__body {
  height: 330px;
  overflow: auto;
}
.submitWrap {
  width: 100%;
  text-align: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.line {
  width: 100%;
  height: 20px;
  background: #edf3fe;
}

.btn-group {
  display: flex;
  justify-content: center;
  margin: 20px;

  .btn {
    width: 124px;
    height: 40px;
    border-radius: 5px;
    margin: 0 20px;
    font-size: 18px;
  }
}
</style>
